import React, { useState, useContext, useEffect } from 'react';
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import Cookies from 'js-cookie';
import { auth } from './firebase';
import { useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios';
import { StatusCodes } from 'http-status-codes';

import { UserContext } from './contexts/UserProvider';

const Login = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [, dispatchUserState] = useContext(UserContext);

    const query = new URLSearchParams(location.search)

    const from_path = query.get('from');

    const authenticate = ({ user }) => {
        dispatchUserState({ type: 'login', user });
        // Get the user's ID token as it is needed to exchange for a session cookie.
        return user.getIdToken().then((idToken) => {
            // Session login endpoint is queried and the session cookie is set.
            // CSRF protection should be taken into account.
            // ...
            const csrfToken = Cookies.get('csrfToken')
            return axios.post('/auth/login', {
                idToken,
                csrfToken
            }).then(() => {navigate(from_path)})
        });
    }

    const signInWithGoogle = async () => {
        const provider = new GoogleAuthProvider();
        try {
            signInWithPopup(auth, provider).then(authenticate);
        } catch (error) {
            console.error("Error signing in with Google", error);
        }
    };

    const signInWithEmail = (e) => {
        e.preventDefault();
        try {
            signInWithEmailAndPassword(auth, email, password)
                .then(authenticate)
        } catch (error) {
            console.error("Error signing in with Email", error);
        }
    }

    const [auth_checked, setAuthChecked] = useState(false);

    useEffect(() => {

        const checkAuth = async () => {

            let res = await fetch('/auth/user');

            if (res.status === StatusCodes.OK) {

                let user = await res.json().catch(err => { console.log('Login failed') });

                dispatchUserState({ type: 'login', user });

                return user;

            } else return false;


        }

        checkAuth().then(res => {

            if (res)
                navigate((from_path), { replace: true })

            else {
                setAuthChecked(true)
            }

        })
        // eslint-disable-next-line
    }, [])

    return (
        auth_checked ? <>
            <main >
                <section>
                    <div>
                        <h1> insentric-root-admin </h1>

                        <form>
                            <div style={{marginBottom : '10px'}}>
                                <label htmlFor="email-address" style={{marginRight : '10px'}}>
                                    Email address
                                </label>
                                <input
                                    id="email-address"
                                    name="email"
                                    type="email"
                                    required
                                    placeholder="Email address"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>

                            <div style={{marginBottom : '10px'}}>
                                <label htmlFor="password" style={{marginRight : '38px'}}>
                                    Password
                                </label>
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    required
                                    placeholder="Password"
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>

                            <div>
                                <button
                                    onClick={signInWithEmail}
                                >
                                    Login
                                </button>
                            </div>
                        </form>


                        <div style={{marginBottom : '10px'}}>
                            <button onClick={signInWithGoogle}>Sign In with Google</button>
                        </div>

                    </div>
                </section>
            </main>
        </> : <div></div>
    )
}

export default Login