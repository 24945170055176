import React, { createContext, useReducer } from "react";

const reducer = (state, action) => {

    switch (action.type) {
        case 'login':
            return {
                ...state,
                auth: true,
                user : action.user
            };
        case 'logout':
            return {
                auth: false,
            };
        default:
            return state;
    }
};


const initialState = {
    auth: false,
    user: null
};

const UserProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <UserContext.Provider value={[state, dispatch]}>
            {children}
        </UserContext.Provider>
    )
};

export const UserContext = createContext(initialState);
export default UserProvider;