import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Form as BootstrapForm } from 'react-bootstrap';
import { Field, ErrorMessage } from 'formik';

export const TextField = ({ id, label, isInvalid, description, optional }) => {
  return <BootstrapForm.Group controlId={id} className='mb-3'>
    <BootstrapForm.Label>{`${label}${optional ? ' (optional)' : ''}`}</BootstrapForm.Label>
    <Field
      type='text'
      name={id}
      as={BootstrapForm.Control}
      isInvalid={isInvalid}
      className='form-input'
    />
    <ErrorMessage name={id} component={BootstrapForm.Control.Feedback} type='invalid' />
    {description && <BootstrapForm.Text muted>
      {description}
    </BootstrapForm.Text>}
  </BootstrapForm.Group>
}

export const PasswordField = ({ id, label, isInvalid, optional }) => {

  const [showPassword, setShowPassword] = useState(false);

  return <BootstrapForm.Group controlId={id} className='mb-3'>
    <BootstrapForm.Label>{`${label}${optional ? ' (optional)' : ''}`}</BootstrapForm.Label>
    <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
      <Field
        type={showPassword ? 'text' : 'password'}
        name={id}
        as={BootstrapForm.Control}
        isInvalid={isInvalid}
        className='form-input'
        style={{ paddingRight: '30px' }}
      />
      <FontAwesomeIcon
        icon={showPassword ? faEyeSlash : faEye}
        style={{
          position: 'absolute',
          right: '10px',
          top: '50%',
          transform: 'translateY(-50%)',
          cursor: 'pointer'
        }}
        onClick={() => setShowPassword(!showPassword)}

      />
    </div>
    <ErrorMessage name={id} component={BootstrapForm.Control.Feedback} type='invalid' />
  </BootstrapForm.Group>

}

export const SelectField = ({ id, label, isInvalid, options }) => {
  return <BootstrapForm.Group controlId={id} className='mb-3'>
    <BootstrapForm.Label>{label}</BootstrapForm.Label>
    <div>
      <Field
        as='select'
        name={id}
        className='form-input'
        isInvalid={isInvalid}
      >
        {options.map(option => <option value={option.value}>{option.label}</option>)}
      </Field>
      <ErrorMessage name={id} component={BootstrapForm.Control.Feedback} type='invalid' />
    </div>
  </BootstrapForm.Group>

}