import React from "react";

import { Navigate, useLocation} from "react-router-dom";

import { UserContext } from './contexts/UserProvider';
import Login from "./Login";

import CreateInstance from './CreateInstance';
const Private = ({ component: Component, ...rest }) => {

  const [state] = React.useContext(UserContext);

  const location = useLocation()
  const path = location.pathname

  if (state.auth){
    console.log('Authenticated');
    return <Component {...rest} />
  }
    
  else {
    console.log('Not Authenticated')
    return <Navigate to = {location.search !== '' ? `/login${location.search}&from=${path}` : '/login?from=' + path} />
  }
  

};


const routes = [
  {
    path: '/deploy',
    element: <Private component={CreateInstance} />
  },
  {
    path: '/',
    children: [
      { path: 'login', element: <Login /> },
    ]
  }
];

export default routes;

