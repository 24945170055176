import React from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import { Container } from 'react-bootstrap';

import Form from './components/Form';
import { TextField, PasswordField, SelectField } from './components/FormFields';

// Import your SVG logo
import logo from './assets/logo.svg';

const fields = [
  {
    label: 'Client',
    id: 'client',
    description: 'Client identifier to be used for this project. Ex: super-awesome-company',
    initialValue: undefined,
    validationSchema: Yup.string().matches(/^[a-z0-9-]+$/, 'Invalid client identifier. Only lower-case letteres and hiphens are allowed').required('Client is required'),
    fieldComponent: TextField,
  },
  {
    label: 'Environment',
    id: 'environment',
    description: 'Environment to be used for this project',
    initialValue: 'prod',
    validationSchema: Yup.string().oneOf(['prod', 'dev', 'stag', 'test']).required('Environment is required'),
    options: [
      { value: 'prod', label: 'Production' },
      { value: 'dev', label: 'Development' },
      { value: 'stag', label: 'Staging' },
      { value: 'test', label: 'Test' },
    ],
    fieldComponent: SelectField,
  },
  {
    label: 'Marketo Munchkin ID',
    id: 'mktoMunchkinId',
    description: 'Marketo Munchkin ID to be used for this project',
    initialValue: undefined,
    validationSchema: Yup.string().matches(/^\d{3}-[A-Z]{3}-\d{3}$/, 'Invalid Marketo Munchkin ID').required('Marketo Munchkin ID is required'),
    fieldComponent: TextField
  },
  {
    label: 'Salesforce Organization ID',
    id: 'sfdcOrgId',
    description: 'Salesforce Organization ID to be used for this project',
    initialValue: undefined,
    validationSchema: Yup.string().matches(/^[a-zA-Z0-9]{15}(?:[a-zA-Z0-9]{3})?$/, 'Invalid Salesforce Organization ID'),
    optional: true,
    fieldComponent: TextField
  },
  {
    label: 'Marketo Client Secret',
    id: 'credentials.mkto_client_secret',
    initialValue: undefined,
    validationSchema: Yup.string(),
    optional: true,
    fieldComponent: PasswordField
  },
  {
    label: 'Marketo Client ID',
    id: 'credentials.mkto_client_id',
    initialValue: undefined,
    validationSchema: Yup.string(),
    optional: true,
    fieldComponent: PasswordField
  },
  {
    label: 'Salesforce Login URL',
    id: 'credentials.sfdc_login_url',
    initialValue: undefined,
    validationSchema: Yup.string(),
    optional: true,
    fieldComponent: TextField
  },
  {
    label: 'Salesforce Username',
    id: 'credentials.sfdc_username',
    initialValue: undefined,
    validationSchema: Yup.string(),
    optional: true,
    fieldComponent: TextField
  },
  {
    label: 'Salesforce Password',
    id: 'credentials.sfdc_password',
    initialValue: undefined,
    validationSchema: Yup.string(),
    optional: true,
    fieldComponent: PasswordField
  },
  {
    label: 'Salesforce Token',
    id: 'credentials.sfdc_token',
    initialValue: undefined,
    validationSchema: Yup.string(),
    optional: true,
    fieldComponent: PasswordField
  }
]

export default function CreateInstance() {

  let initialValues = { credentials: {} };
  let validationSchema = { credentials: {} };

  fields.forEach((field) => {
    if (field.id.startsWith('credentials.')) {
      // If the first part is 'credentials', add to the nested object
      initialValues.credentials[field.id.split('credentials.')[1]] = field.initialValue;
      validationSchema.credentials[field.id.split('credentials.')[1]] = field.validationSchema;
    } else {
      // Otherwise, add directly to the initialValues/validationSchema object
      initialValues[field.id] = field.initialValue;
      validationSchema[field.id] = field.validationSchema;
    }
  });

  const formikValidationSchema = Yup.object().shape({
    ...validationSchema,
    credentials: Yup.object().shape(validationSchema.credentials),
  });

  const handleSubmit = async (values, { setSubmitting }) => {

    let { credentials, ...variables } = values;

    variables = Object.fromEntries(Object.entries(variables).filter(([_, v]) => v));
    credentials = Object.fromEntries(Object.entries(credentials).filter(([_, v]) => v));

    await axios.post('/api/instances/deploy', { ...variables, credentials })

    setSubmitting(false);
  };

  return (
    <div className='wrapper'>
      <Container className='form-container'>
        <div className='w-100'>

          <div className='text-center mb-4'>
            <img src={logo} alt='Logo' height='40' />
          </div>

          <Form
            fields={fields}
            initialValues={initialValues}
            validationSchema={formikValidationSchema}
            handleSubmit={handleSubmit} />

        </div>
      </Container>
    </div>

  );
}

