
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import './styles.css'; 


import UserProvider from "./contexts/UserProvider"

import { useRoutes } from 'react-router-dom';

import routes from './routes';

const App = () => {
  const routing = useRoutes(routes);

  
  return (
    <UserProvider>
      {routing}
    </UserProvider>
  );
};
export default App;
