import { Formik, Form as FormikForm } from 'formik';
import { Button } from 'react-bootstrap';

export default function Form({ fields, initialValues, validationSchema, handleSubmit }) {
  
  return <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={handleSubmit}
  >
    {({ touched, errors, isSubmitting }) =>

      <FormikForm className='form'>

        {fields.map(({ label, id, description, optional, options, fieldComponent: FieldComponent }) =>
          <FieldComponent id={id} label={label} description={description} options={options} optional={optional} isInvalid={touched[id] && errors[id]} />
        )}

        <Button type='submit' variant='primary' disabled={isSubmitting}>
          {isSubmitting ? 'Submitting' : 'Submit'}
        </Button>

      </FormikForm>
    }
  </Formik>
}